import Layout from '../../components/layout'
import React from 'react'
import SEO from '../../components/seo'
import ServiceHeader from '../../components/sections/ServiceHeader'
import { graphql } from 'gatsby'
import { StaticImage,GatsbyImage,getImage  } from 'gatsby-plugin-image'
import HeadingContentIconBoxes from '../../components/sections/HeadingContentIconBoxes'
import { default as SbsIHCB } from '../../components/sections/SidebysideImageHeadingContentButton'
import CaseStudy from '../../components/sections/CaseStudy'
import SwiperAccordion from '../../components/SwiperAccordion'
import CallToAction from '../../components/sections/CallToAction'
import FAQ from '../../components/sections/Faq'
export default function SeoPage(props) {
  const featuredWork = props.data.featuredWork.edges[0].node;
  const featuredWorkImage = getImage(featuredWork.banner)
  return (
    <>
      <Layout>
        <SEO
          title="Website Design Kuala Lumpur | Website Design Services Kuala Lumpur| Majiska"
          description="Website design Kuala Lumpur. Performance-driven, money-making web design and digital marketing solutions that will grow your business. Let's chat."
          isCollectionPage={true}
          about={{ type: 'service' }}
          service={{
            name: 'Web Design ',
            sameAs: 'https://www.wikidata.org/wiki/Q190637',
            description: 'Result driven website design on top of appealing aesthetics'
          }}
         
        />
        <main className="">
          <ServiceHeader
            heading="Web Design"
            subheading="Result driven website design on top of appealing aesthetics"
            list={[
              {
                iconName: 'handshake',
                description:
                  "We are your digital success partner that takes care of your business growth",
              },
              {
                iconName: 'brain',
                description:
                  "7 of Malaysia's leading digital performance specialists in one local agency.",
              },
              {
                iconName: 'strategy-action',
                description:
                  "Trusted by Malaysia's leading brands, but proudly partnering many smaller ones with big aspirations",
              },
              {
                iconName: 'certified',
                description:
                  "Kuala Lumpur’s leading independent Google Partner (accredited by Google).",
              },
            ]}
            image={
              <StaticImage src="../../images/malaysia-top-website-design-expert.png" alt="A dinosaur" />
            }
            isImageSquare
          />

          <HeadingContentIconBoxes
            heading="Why Majiska for Web Design?"
            content={`<p>Why should you work with our web design agency? We do more than just web services. Aesthetics play just a small part in the design. A beautiful website design is meaningless unless it accomplishes your objectives. It's all about achieving the highest possible ROI by web design.</p>`}
            iconBoxStyle={2}
            iconBoxObj={[
              {
                name: 'SEO Friendly',
                description:
                  'Our SEO specialists are among the top in the industry and we ensure that your website is optimised for SEO.',
                iconName: 'seo',
              },
              {
                name: 'E-Commerce',
                description:
                  "It's all about increasing revenue and growing faster.  By using the best platforms and the most appropriate payment gateways, we can provide excellent e-commerce store design in Kuala Lumpur.",
                iconName: 'shopping',
              },
              {
                name: 'WordPress Specialists',
                description:
                  'Using our free WordPress training sessions, you can avoid extra website maintenance costs and keep your website current.',
                iconName: 'wordpress',
              },
            ]}
            
            
          />
          <SbsIHCB
            heading="Home to the best website design specialists."
            content={
              <p>
                Behind our time-tested data-driven approach and cutting-edge technology are some of the industry's brightest minds. They set our Kuala Lumpur agency, web design services, and client outcomes apart through their extensive training, Google accreditation, and vast experience.
              </p>
            }
            image={
              <StaticImage src="../../images/malaysia-top-digital-marketing-specialists.png" alt="A dinosaur" />
            }
            isEven
            isImageSquare
            padding="py-0"
          />
          <HeadingContentIconBoxes
            heading="Our approach to web design."
            content={`Fine-tuned methodology + Loveable specialists + thorough analysis + specialized tools. This is the fundamental principle for our SEO services success. It allows us as a performance first digital marketing agency in making an accurate, consistent first impression on target audience members through well-crafted messaging that is perceived as valuable by potential customers. This will boost your SEO campaign if you're trying to get found on page one, to boost rankings, help the company with business goals, or promote your brand.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Conversion-Driven Design',
                description:
                  'Websites that are modular in nature, with custom templates and landing pages.  While aesthetics in web design can vary, our ability to create engaging and conversion-oriented websites does not.',
                iconName: 'roi-driven',
              },
              {
                name: 'Search Engine Ready',
                description:
                  'No exposure online means no customers and no sales. Our web techs work revolves around creating accessible, easy-to-to-use, and friendly websites for search engines.',
                iconName: 'robot',
              },
              {
                name: 'Security',
                description:
                  'Security is essential. All of our websites are secured using industry-standard Secure Socket Layers (SSL) technology, ensuring that your data remains private and encrypted.',
                iconName: 'strong',
              },
              {
                name: 'Mobile Ready',
                description:
                  "Google's Mobile-First index and user behaviour have fundamentally altered the landscape. Our websites are designed to adhere to industry standards both now and in the future.",
                iconName: 'mobile',
              },
              {
                name: 'User Experience (UX)',
                description:
                  'Better user experience leads to higher conversion rate. We understand the components that contribute to an exceptional customer experience on your website.',
                iconName: 'architecture',
              },
              {
                name: 'Platform Management',
                description:
                  'Maintenance is equally critical as construction. We manage your WordPress CMS and plugin updates on a regular basis to insure that your website performs optimally.',
                iconName: 'configure',
              },
              {
                name: 'Built For Speed',
                description:
                  'With the new Google Search algorithm that priortise speed, we can elevate your website to stand out more compared to your competitors. ',
                iconName: 'performance',
              }
            ]}
          />
          <CaseStudy
            title={featuredWork.title}
            content={featuredWork.description}
            image={
              <GatsbyImage image={featuredWorkImage} className="stats__fimage" alt={`case study of ${featuredWork.title}`} />
            }
            to={`/work/${featuredWork.slug}`}
            stats={featuredWork.stats}
          />
          <SbsIHCB
            heading="Transparency and accountability."
            content={
              <>
                <h3>
                  Website design that delivers.
                </h3>
                <p className="">
                  We use a myriad of different metrics to determine the success of our web services, ranging from website traffic to click-through rates. Ultimately, the most important metric would be the return on investment for your bottom line.</p>
                <h3>
                  Powerful web and campaign analytics.
                </h3>
                <p className="">
                  The proof is in the pudding. We plan and build your marketing strategies, and watch as they grow. We'll have the modern software to track our progress, in real-time and with the Performance Forecasting tool.</p>
                <h3>
                  Cutting-edge web tech that gives you an edge.

                </h3>
                <p className="">
                  Our web specialists in Kuala Lumpur insure that we leverage emerging technology to provide a meaningful and sustainable benefit to ourselves and our clients in Western Australia and the eastern states. By combining artificial intelligence, machine learning, predictive analytics and automation, we are able to gather insights that allow us to achieve a higher return on investment.</p>
              </>
            }
            image={
              <StaticImage src="../../images/malaysia-best-digital-marketing-company.png" alt="A dinosaur" />
            }
            isImageSquare
            isEven={true}
            padding="py-0"
          />
          <SbsIHCB
            heading="The benefits of smart web design."
            content={
              <>
                <p>
                Take advantage of all the advantages of the WordPress CMS, but with just the functionality you and your business need, including automatic monthly plug-in updates. Our proprietary web design approach is lean and intelligent, optimising the speed and security of your website while reducing website management and maintenance costs.</p>
                <ul>
                  <li>Receive complimentary WordPress training sessions.</li>
                  <li>You are responsible for updating the content on your website.</li>
                  <li>Enjoy the benefits of reassuring website security.</li>
                  <li>SEO will help you increase website traffic.</li>
                  <li>Attract highly specific audiences</li>
                  <li>Increase conversions and user experience (UX)</li>
                </ul>
              </>
            }
            image={
              <StaticImage src="../../images/malaysia-digital-marketing-strategy-partner.png" alt="A dinosaur" />
            }
            isImageSquare
            padding="py-0"
          />
          <SbsIHCB
            className={``}
            heading={
              <>
                The importance of a reputable web design company.

              </>
            }
            content={
              <>
                <p>
                A well-designed website will help elevate your brand, from initial impressions to a consistent user experience that feels as good as it looks. However, as any credible web design agency would tell you, web design alone will not produce the results you want.</p>
              </>
            }
            isFull={true}
           
          />
          {/* <SwiperAccordion

            content={[
              {
                name: 'COOL',
                content:
                  '<p>Sed augue ipsum,<br> egestas nec, vestibulum et, malesuada adipiscing, dui. Phasellus dolor. Vivamus elementum semper nisi.</p>',
                image: 'imageSEO',
              },
              {
                name: 'Digital Advertising',
                content:
                  'Vivamus euismod mauris. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Praesent congue erat at massa.',
                image: 'imagePPC',
              },
              {
                name: 'Google Ads',
                content:
                  'Aliquam eu nunc. Donec sodales sagittis magna. Nunc sed turpis. Vivamus quis mi.',
                image: 'imageSocial',
              },
              {
                name: 'Remarketing',
                content:
                  'VEtiam ut purus mattis mauris sodales aliquam. Mauris turpis nunc, blandit et, volutpat molestie, porta ut, ligula. Nam commodo suscipit quam. Aliquam lobortis.',
                image: 'imageRemarketing',
              },
            ]}
            className={`pb-resp-16-32 bg-theme`}
          /> */}
          <HeadingContentIconBoxes
            heading="Are we right for you?"
            content={`Consider our Google Ads case studies. As you can see, we've assisted clients of all sizes and locations in Malaysia in achieving their digital marketing and business objectives. And if you have well-defined goals, we can help you achieve those.`}
            iconBoxStyle={3}
            iconBoxObj={[
              {
                name: 'Micro',
                description:
                  'Our smaller clients account for 28% of businesses with one to ten staff and are widely regarded as having a growth mentality.',
                iconName: 'micro',
              },
              {
                name: 'Small',
                description:
                  'We identify 25% of our clients as small companies with 11–50 employees.',
                iconName: 'small',
              },
              {
                name: 'Medium',
                description:
                  'We identify 25% of our clients as medium-sized firms with 50–100 employees.',
                iconName: 'medium',
              },
              {
                name: 'Enterprise',
                description:
                  '22% of our customers are larger companies with more than 100 employees.',
                iconName: 'enterprise',
              }
            ]}
            padding="py-0"
          />
         
          <CallToAction />
        </main>
      </Layout>
    </>
  )
}


export const pageQuery = graphql`
{
  featuredWork: allContentfulPastWorks(
    filter:{markAsFeatured: {eq: true}}
  ) {
    edges {
      node {
        id
        title
        slug
        description
        date: createdAt(formatString: "MMMM DD, YYYY")
        banner: bannerImage {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        stats{
          number,
          description,
        }
      }
    }
  }
}
`
